.pageFooter {
  position: absolute;
  bottom: 0;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: calc(6px + 2vmin);
  padding-top: 10px;
  background-color: #7477c1;
}

@media only screen and (max-width: 796px) {
  .pageFooter {
    position: absolute;
    bottom: 0;
    height: 70px;
    padding: 15px;
    width: -webkit-fill-available;
    align-items: center;
    justify-content: center;
    font-size: calc(6px + 2vmin);
    background-color: #7477c1;
  }

  .footerText {
    padding-right: 40px;
  }

  .footerText > p {
    font-size: 9px;
  }

  .footerImages {
    display: flex;
  }

  .footerImages > img {
    height: 10px;
  }
}

#link:hover {
  color: rgb(48, 48, 48);
}

#link {
  text-decoration: none;
  color: black;
}

.footerText {
  font-size: small;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}
.footerText > p {
  color: black;
  margin: 0px;
  font-family: "Kumbh Sans", sans-serif;
  font-style: normal;
  font-optical-sizing: auto;
  display: flex;
  justify-content: center;
  text-align: center;
}

.footerImages > img {
  height: 50px;
  padding: 5px;
}
