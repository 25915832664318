#contactForm {
  display: flex;
  flex-direction: column;
  width: 700px;
  margin-right: 30px;
  padding: 10px;
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

#availabilityNotice {
  text-align: center;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 900;
  margin-right: 40px;
  margin-left: 40px;
}

#contactForm > label {
  color: black;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 500;
  padding-top: 10px;
}

#contactForm > h5 {
  color: red;
}

input[type="text"],
select,
textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  resize: vertical;
  font-family: monospace !important;
  font-size: medium;
}

input[type="submit"] {
  background-color: #04aa6d;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 20px;
  color: white;
  padding: 12px 20px;
  margin-top: 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #a5d6a8;
}

.pageContainer {
  position: relative;
  min-height: 100vh;
}
.Contact {
  display: flex;
  flex-direction: row;
  font-family: "Kumbh Sans", sans-serif;
  padding-top: 20px;
  font-size: 20px;
  color: white;
  margin-left: 40px;
  margin-right: 40px;
  justify-content: space-evenly;
  /* padding: 10px; */
  padding-bottom: 120px; /* Footer height */
}
.Contact {
  p {
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
  }
}

@media only screen and (max-width: 700px) {
  .Contact {
    padding-top: 20px;
    flex-direction: column;
    margin-right: 15px;
    margin-left: 15px;
  }

  #contactForm {
    width: auto;
    margin-right: 0px;
    margin-left: 0px;
  }

  .links {
    margin-top: 20px;
    padding: 20px;
  }
  #individual-links {
    font-size: smaller;
  }
  #availabilityNotice {
    text-align: center;
    font-family: "Kumbh Sans", sans-serif;
    font-weight: 900;
    margin-right: 5px;
    margin-left: 5px;
  }
}

#individual-links:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
