.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(30px + 2vmin);
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 900;
  color: white;
  background-color: #7477c1;
  letter-spacing: 0px;
}

.App-header > h1 {
  margin-bottom: 0;
  margin-top: 20px;
  text-align: center;
}

#headerLink:visited {
  text-decoration: none;
  color: white;
}

#headerLink:hover {
  color: #f8b3b3;
  cursor: pointer;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .5s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='%23f8b3b3' stroke-width='1.5' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 30px;
  border-bottom: 0;
  padding-bottom: 0.3em;
}

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: fixed;
  background-color: #7477c1;
  height: 100%;
  z-index: 100;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 900;
  color: white;
  font-size: 40px;
  animation: fadein 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0 !important;
    height: 0;
  }
  to {
    height: 100%;
  }
}

.modalExit {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 30px;
  padding: 10px;
  filter: invert(99%) sepia(1%) saturate(497%) hue-rotate(11deg)
    brightness(114%) contrast(80%);
}
.menuOpenIcon {
  height: 35px;
  padding-left: 10px;
  padding-top: 40px;
  filter: invert(99%) sepia(1%) saturate(497%) hue-rotate(11deg)
    brightness(114%) contrast(80%);
}
.menuOpenIcon:hover {
  filter: brightness(0) saturate(100%) invert(65%) sepia(7%) saturate(4018%)
    hue-rotate(303deg) brightness(100%) contrast(105%);
  cursor: pointer;
}

.modalExit:hover {
  color: #f8b3b3;

  filter: brightness(0) saturate(100%) invert(65%) sepia(7%) saturate(4018%)
    hue-rotate(303deg) brightness(100%) contrast(105%);
  cursor: pointer;
}

#modalLink:hover {
  color: #f8b3b3;
  cursor: pointer;
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 30px;
  border-bottom: 0;
  padding-bottom: 0.3em;
}

#modalLink {
  background: none !important;
  border: none;
  padding: 20px !important;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 900;
  font-size: calc(10vw + 1vh);
  height: auto;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

#headerLink {
  background-image: none;
  background-size: 3px 3px;
  color: white;
  font-size: 40px;
  background: none;
  border: none;
  padding: 10 !important;
  font-family: "Kumbh Sans", sans-serif;
  font-weight: 900;
  font-size: 40px;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.About {
  padding-bottom: 120px; /* Footer height */
  padding-top: 20px;
  font-size: 20px;
  color: white;
  justify-content: space-evenly;
}

.homeTextBottom {
  padding-left: 40px;
  padding-right: 40px;
}
.homeImgOLD {
  float: right;
  height: 350px;
  margin-left: 40px;
  margin-bottom: 20px;
  border: 10px solid #f8b3b3;
  border-radius: 25% 10%;
}
.homeImg {
  --r: 10px; /* radius of circles */
  float: right;
  height: 350px;
  padding: calc(1.5 * var(--r));
  margin-left: 40px;
  margin-bottom: 20px;
  background: #f8b3b3;
  mask: linear-gradient(#000 0 0) no-repeat 50% / calc(100% - 2 * var(--r))
      calc(100% - 2 * var(--r)),
    radial-gradient(farthest-side, #000 97%, #0000) 0 0 / calc(2 * var(--r))
      calc(2 * var(--r)) round;
}

.homeTop {
  padding-left: 40px;
  padding-right: 40px;
  margin-bottom: 70px;
}
.About {
  p {
    font-family: "Kumbh Sans", sans-serif;
    font-style: normal;
    font-optical-sizing: auto;
  }
}

.pageFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bottomImg {
  display: none;
}

@media only screen and (max-width: 700px) {
  .About {
    display: contents;
    margin: 0;

    height: 100vh;
    padding-top: 20px;
    padding-bottom: 120px;
    font-size: 20px;
    color: white;
    justify-content: space-evenly;
    /* padding: 10px; */
  }
  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .homeImg {
    display: none;
    border: 10px solid black;
    border-radius: 25% 10%;
  }
  .homeText {
    grid-column: 1 / 2;
    grid-row: 1;
  }

  .centeredText {
    text-align: center;
  }

  .homeTop {
    margin-bottom: 0px;
  }

  .homeTextBottom {
    padding-bottom: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center; /* align horizontal */
    align-items: center;
  }

  .bottomImg {
    margin-top: 5px;
    display: flex;
    --r: 10px; /* radius of circles */
    height: 350px;
    padding: calc(1.5 * var(--r));

    background: #f8b3b3;
    mask: linear-gradient(#000 0 0) no-repeat 50% / calc(100% - 2 * var(--r))
        calc(100% - 2 * var(--r)),
      radial-gradient(farthest-side, #000 97%, #0000) 0 0 / calc(2 * var(--r))
        calc(2 * var(--r)) round;
  }
}
#homeLink {
  background-color: white;
  border: none;
  padding: 0 !important;
  font-family: "Kumbh Sans", sans-serif;
  font-size: 20px;
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#homeLink:hover {
  cursor: pointer;
  background-color: rgb(199, 199, 199);
}

@media screen and (max-width: 700px) {
  .phoneMenu {
    display: block;
  }
  .fullScreenMenu {
    display: none;
  }
}
@media screen and (min-width: 700px) {
  .phoneMenu {
    display: none;
  }
  .fullScreenMenu {
    display: block;
  }
}

.logo {
  display: none;
}

.logoAtHome {
  display: none;
}

.logo:hover {
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .logo {
    display: flex;
    padding-top: 40px;
    height: 110px;
  }

  .logoAtHome {
    display: flex;
    padding-top: 40px;
    height: 110px;
  }
}

.logolandscape {
  padding-top: 20px;
  max-height: 110px;

  max-width: 90%;
}
.logolandscape:hover {
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .logolandscape {
    display: none;
  }
}
