.pageContainer {
  position: relative;
  min-height: 100vh;
}
.Treatments {
  display: flex;
  padding-bottom: 120px; /* Footer height */

  /* flex-direction: column; */
  /* align-items: center; */
  font-size: 20px;
  padding-top: 20px;
  color: white;
  margin-left: 40px;
  margin-right: 40px;
}

p {
  font-family: "Kumbh Sans", sans-serif;

  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

#treatmentsText {
  float: left;
}

li {
  padding: 5px;
}

#treatmentsTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

#treatmentsTable th {
  vertical-align: bottom;
}

#treatmentsTable tr:hover {
  background-color: #e8e8e8;
}

#treatmentsTable td,
#treatmentsTable th {
  padding: 8px;
}

#treatmentsTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-right: 40px;
  /* margin-left: 40px; */
  font-family: "Kumbh Sans", sans-serif;
  color: black;
}

#treatmentsTableMobile tr:nth-child(even) {
  background-color: #f2f2f2;
}

#treatmentsTableMobile th {
  vertical-align: bottom;
}

#treatmentsTableMobile tr:hover {
  background-color: #e8e8e8;
}

#treatmentsTableMobile td,
#treatmentsTableMobile th {
  padding: 8px;
}

#treatmentsTableMobile {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin-right: 40px;
  /* margin-left: 40px; */
  font-family: "Kumbh Sans", sans-serif;
  color: black;
}

@media screen and (max-width: 700px) {
  #treatmentsTable {
    display: none;
  }
}
@media screen and (min-width: 700px) {
  #treatmentsTableMobile {
    display: none;
  }
}
